import React from 'react'
import ArticleBox from '../../articleBox/ArticleBox'

export default function Videoer() {
    return (
        <div>
            <ArticleBox>
                <ArticleBox.Title title="Videoer" />
                {/* <ArticleBox.UnderTitle undertitle="undertitel" /> */}
                <ArticleBox.Article
                    article={
                        <p>
                            <center>
                                <iframe src="https://www.youtube.com/embed/TFwkdmMfHVc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>
                                <iframe src="https://www.youtube.com/embed/wHXwVvTKiMo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>
                            </center>
                        </p>
                    }
                />
            </ArticleBox>
        </div>
    )
}

function clickHandler(url) {
    window.open(url)
}
